<template>
  <div class="all-height">
    <div class="">
      <v-card loader-height="2" class="shadow-off" :loading="loading" :disabled="loading">
        <v-card-text class="">
          <div>
            <div class="d-flex mb-0">
              <div class="d-flex align-center">
                <span class="display-1">{{formatedData.length}}</span>
                <span class="ml-2 line-1">Reporting Units<br />Count</span>
              </div>
              <v-spacer></v-spacer>
              <v-tooltip bottom content-class='tooltip-bottom'>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" name="faq" @click.stop="faqdialog = true;" icon small>
                    <v-icon>mdi-comment-question-outline</v-icon></v-btn>
                </template>
                <span>FAQ</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-0 px-0" icon v-on="on" @click="refreshData()" small>
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </div>

            <lb-datatablenew :headers="headers" :items="formatedData"
              :enableslot="['action', 'submitterFew', 'approverFew', 'regional_approverFew', 'managerFew']"
              :loading="loading" multiselectkey="_id" v-if="data.length > 0">
              <template v-slot:additionalinfo="{ item, selected, selectall }">
                <!-- <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="shadow-off" @click="getDownloadAllAccesslist();"><v-icon>mdi-table-arrow-down</v-icon></v-btn>
                  </template>
                  <span>Download All Access Detail</span>
                </v-tooltip> -->
                <v-tooltip bottom content-class="tooltip-bottom" v-if="selectall || selected.length > 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" color="" @click="editData(item, 'bulk', selected)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Bulk Update Access</span>
                </v-tooltip>
                <div class="text-right">
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="pendingdialog=true"
                        :disabled="pendingrequestlist.length === 0">
                        <v-icon>mdi-timer-sand</v-icon>
                      </v-btn>
                    </template>
                    <span>View pending approval</span>
                  </v-tooltip>
                </div>
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on"
                      @click.stop="editData(item, 'file', [])">
                      <v-icon>mdi-file-upload</v-icon>
                    </v-btn>
                  </template>
                  <span>Bulk RU level upload</span>
                </v-tooltip>
              </template>
              <template v-slot:submitterFew="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v,k) in item.submitterFew" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="35" class="mr-1" :style="`background-color:${v.color}`" v-bind="attrs" v-on="on">
                        {{v.code}}
                      </v-avatar>
                    </template>
                    <span>{{v.name}}</span>
                  </v-tooltip>
                  <v-avatar size="35" v-if="item.__submitterplus > 0" class="mr-1 border-on">
                    +{{item.__submitterplus}}
                  </v-avatar>
                </div>
              </template>
              <template v-slot:approverFew="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v,k) in item.approverFew" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="35" class="mr-1" :style="`background-color:${v.color}`" v-bind="attrs" v-on="on">
                        {{v.code}}
                      </v-avatar>
                    </template>
                    <span>{{v.name}}</span>
                  </v-tooltip>
                  <v-avatar size="35" v-if="item.__approverplus > 0" class="mr-1 border-on">
                    +{{item.__approverplus}}
                  </v-avatar>
                </div>
              </template>
              <template v-slot:regional_approverFew="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v,k) in item.regional_approverFew" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="35" class="mr-1" :style="`background-color:${v.color}`" v-bind="attrs" v-on="on">
                        {{v.code}}
                      </v-avatar>
                    </template>
                    <span>{{v.name}}</span>
                  </v-tooltip>
                  <v-avatar size="35" v-if="item.__regional_approverplus > 0" class="mr-1 border-on">
                    +{{item.__regional_approverplus}}
                  </v-avatar>
                </div>
              </template>
              <template v-slot:managerFew="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v,k) in item.managerFew" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="35" class="mr-1" :style="`background-color:${v.color}`" v-bind="attrs" v-on="on">
                        {{v.code}}
                      </v-avatar>
                    </template>
                    <span>{{v.name}}</span>
                  </v-tooltip>
                  <v-avatar size="35" v-if="item.__managerplus > 0" class="mr-1 border-on">
                    +{{item.__managerplus}}
                  </v-avatar>
                </div>
              </template>
              <template v-slot:action="{ item }">
                <v-tooltip bottom content-class="tooltip-bottom" v-if="$nova.hasRight(right, 'edit')">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" color="" @click="editData(item, 'single', item._id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Update for Actual Cycle</span>
                </v-tooltip>
              </template>
            </lb-datatablenew>
            <div v-else-if='loading' class="title grey--text pa-5 text-center">
              Please wait as we load your data
            </div>
            <div v-else class="title grey--text pa-5 text-center">
              No Data Found
            </div>
          </div>
        </v-card-text>
      </v-card>

      <lb-dialog v-model="addeditrudialog" heading="Update Access details" width="800" :loading="addeditruloading">
        <template v-slot:body>
          <div>
            <div class="subtitle-1 mb-2 font-weight-bold" v-if="!bulksubmit">
              <span>{{addeditrudata.code}}</span>
              <v-icon>mdi-circle-small</v-icon>
              <span>{{addeditrudata.name}}</span>
            </div>
            <div v-else>
              <div class="d-flex align-center pb-4">
                <span class="display-1">{{(addeditrudata._id || []).length}}</span>
                <span class="ml-2 line-1-2">Reporting units<br />Selected</span>
              </div>
            </div>
            <div class="mb-3" v-if="!bulksubmit">
              <v-chip small label outlined class="pr-0 mr-2">
                <span>Network</span>
                <v-chip small label class="ml-2">{{addeditrudata.network}}</v-chip>
              </v-chip>
              <v-chip small label outlined class="pr-0 mr-2">
                <span>Region</span>
                <v-chip small label class="ml-2">{{addeditrudata.region}}</v-chip>
              </v-chip>
              <v-chip small label outlined class="pr-0 mr-2">
                <span>Country</span>
                <v-chip small label class="ml-2">{{addeditrudata.country}}</v-chip>
              </v-chip>
            </div>
            <div class="mb-1">
              <div class="">
                <span class="font-weight-bold">Local Preparer(s):</span>
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="addusertype='submitter';adduserdialog=true;adduservalue=''" v-bind="attrs" v-on="on"
                      icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                  </template>
                  <span>Add Preparer</span>
                </v-tooltip>
              </div>
              <div v-if="(addeditrudata.submitter || []).length > 0">
                <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.submitter"
                  :style="`background-color:${v.color}`" :key="k">
                  {{v.name}}
                  <v-btn small icon class="ml-1"
                    @click="removeAccess(k, 'submitter')"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-chip>
              </div>
              <div v-else class="grey--text px-4">No user selected</div>
            </div>
            <div class="mb-1">
              <div class="">
                <span class="font-weight-bold">Local Approver(s):</span>
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="addusertype='approver';adduserdialog=true;adduservalue=''" v-bind="attrs" v-on="on"
                      icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                  </template>
                  <span>Add Approver</span>
                </v-tooltip>
              </div>
              <div v-if="(addeditrudata.approver || []).length > 0">
                <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.approver"
                  :style="`background-color:${v.color}`" :key="k">
                  {{v.name}}
                  <v-btn small icon class="ml-1"
                    @click="removeAccess(k, 'approver')"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-chip>
              </div>
              <div v-else class="grey--text px-4">No user selected</div>
            </div>
            <div class="mb-1">
              <div class="">
                <span class="font-weight-bold">Regional Approver(s):</span>
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="addusertype='regional_approver';adduserdialog=true;adduservalue=''" v-bind="attrs"
                      v-on="on" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                  </template>
                  <span>Add Regional Approver</span>
                </v-tooltip>
              </div>
              <div v-if="(addeditrudata.regional_approver || []).length > 0">
                <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.regional_approver"
                  :style="`background-color:${v.color}`" :key="k">
                  {{v.name}}
                  <v-btn small icon class="ml-1"
                    @click="removeAccess(k, 'regional_approver')"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-chip>
              </div>
              <div v-else class="grey--text px-4">No user selected</div>
            </div>
            <div class="mb-1">
              <div class="">
                <span class="font-weight-bold">Manager(s):</span>
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="addusertype='manager';adduserdialog=true;adduservalue=''" v-bind="attrs" v-on="on"
                      icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                  </template>
                  <span>Add Manager</span>
                </v-tooltip>
              </div>
              <div v-if="(addeditrudata.manager || []).length > 0">
                <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.manager"
                  :style="`background-color:${v.color}`" :key="k">
                  {{v.name}}
                  <v-btn small icon class="ml-1"
                    @click="removeAccess(k, 'manager')"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-chip>
              </div>
              <div v-else class="grey--text px-4">No user selected</div>
            </div>
            <lb-divider class="mt-3 mb-2"></lb-divider>
            <div>
              <div class="subtitle-1 text-center"
                v-if="[...userdatachange_submitter, ...userdatachange_approver, ...userdatachange_regional_approver, ...userdatachange_manager].length === 0">
                No changes performed/ No alterations performed</div>
              <div v-else>
                <div class="d-flex align-center">
                  <div class="subtitle-1">Changes</div>
                  <v-spacer></v-spacer>
                  <v-chip outlined class="border-left-error" small>
                    Removed
                  </v-chip>
                  <v-chip outlined class="ml-2 border-left-success" small>
                    Added
                  </v-chip>
                </div>
                <div v-if="(userdatachange_submitter || []).length > 0">
                  <div class="font-weight-bold">Preparer(s):</div>
                  <template v-for="(v,k) in (userdatachange_submitter || [])">
                    <v-chip :class="`mr-1 mb-1 pr-0 ${v.action==='remove'?'border-left-error':'border-left-success'}`"
                      small :style="`background-color:${v.user.color}`" :key="k">
                      {{v.user.name}}
                      <v-btn small icon class="ml-1"
                        @click="undoChange(v.user?._id, k, 'submitter', v.action)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </template>
                </div>
                <div v-if="(userdatachange_approver || []).length > 0">
                  <div class="font-weight-bold">Approver(s):</div>
                  <template v-for="(v,k) in (userdatachange_approver || [])">
                    <v-chip :class="`mr-1 mb-1 pr-0 ${v.action==='remove'?'border-left-error':'border-left-success'}`"
                      small :style="`background-color:${v.user.color}`" :key="k">
                      {{v.user.name}}
                      <v-btn small icon class="ml-1"
                        @click="undoChange(v.user?._id, k, 'approver', v.action)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </template>
                </div>
                <div v-if="(userdatachange_regional_approver || []).length > 0">
                  <div class="font-weight-bold">Regional Approver(s):</div>
                  <template v-for="(v,k) in (userdatachange_regional_approver || [])">
                    <v-chip :class="`mr-1 mb-1 pr-0 ${v.action==='remove'?'border-left-error':'border-left-success'}`"
                      small :style="`background-color:${v.user.color}`" :key="k">
                      {{v.user.name}}
                      <v-btn small icon class="ml-1"
                        @click="undoChange(v.user?._id, k, 'regional_approver', v.action)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </template>
                </div>
                <div v-if="(userdatachange_manager || []).length > 0">
                  <div class="font-weight-bold">Manager(s):</div>
                  <template v-for="(v,k) in (userdatachange_manager || [])">
                    <v-chip :class="`mr-1 mb-1 pr-0 ${v.action==='remove'?'border-left-error':'border-left-success'}`"
                      small :style="`background-color:${v.user.color}`" :key="k">
                      {{v.user.name}}
                      <v-btn small icon class="ml-1"
                        @click="undoChange(v.user?._id, k, 'manager', v.action)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </template>
                </div>
              </div>
            </div>
            <lb-divider class="mt-3 mb-2" v-if="(ruUpdateErrorData || []).length > 0"></lb-divider>
            <div class=" align-center" v-if="(ruUpdateErrorData || []).length > 0">
              <div class="subtitle-1">Invalid Changes</div>
              <div v-if="(ruUpdateErrorData || []).length > 0">
                <!-- <div class="font-weight-bold">User(s):</div> -->
                <template v-for="(v, k) in (ruUpdateErrorData || []) ">
                  <v-chip :class="`mr-3 mb-1 pr-3 border-left-error `" small :key="k">
                    {{ ruidcodemap[v.rucode] }}
                  </v-chip>
                  <template v-for="(userid, key) in (v.userids || [])">
                    <v-chip :class="`mr-1 mb-1 pr-3 border-left-error `" small :key="key">
                      {{ userlist[userid].email || []}}
                    </v-chip>
                  </template>
                  <br :key="k">
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color=" primary"
            v-if="[...userdatachange_submitter, ...userdatachange_approver, ...userdatachange_regional_approver, ...userdatachange_manager].length > 0"
            @click="updateUserAccessChange()">Update</v-btn>
        </template>
      </lb-dialog>

      <v-dialog v-model="adduserdialog" max-width="400" persistent>
        <v-card class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Select a user</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="adduserdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="mt-4 pt-4">
            <v-row>
              <v-col cols="12" class="my-0 py-1">
                <lb-dropdown hidedetails v-model="adduservalue" :items="filteredUserListArr" itemvalue="_id"
                  itemtext="newdisplay" label="Users" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="d-flex">
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="addUser()">Select</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <lb-dialog v-model="pendingdialog" heading="Pending approval list" width="800" :loading="pendingloading">
        <template v-slot:body>
          <div>
            <IndividualApprovalsList :data="pendingrequestlist" @loading="setPendingLoading" :key="new Date().getTime()" />
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="bulksubmitrudialog" heading="Bulk Upload RU Level Access"
        :width="(bulksubmitrufiledata.length > 0)?'1200':'500'" :loading="bulksubmitruloading">
        <template v-slot:body>
          <div>
            <div class="mb-2 d-flex align-center justify-center">
              <v-btn color="primary" small class="mr-2"
                @click="$nova.downloadFile('', {}, '/v2/varianceanalysis/reportingunit/getbulkrusubmittemplate')">Download
                Template</v-btn>
              <lb-file v-model="bulksubmitrufile" label="" class="py-0 my-0" :loading="fileprocessing['file'] === 1"
                :drag="false" hidedetails @change="readBulkSubmitFile('file')" :displayname="false"
                accept=".xlsx,.xls,.xlsm,.xlsb" />
            </div>
            <div v-if="bulksubmitrufiledata.length > 0">
              <v-simple-table class="v-table-color"     dense>
                <thead>
                  <tr>
                    <th scope="rucode" class="">RU Code</th>
                    <th scope="runame" class="">RU Name</th>
                    <th scope="l_preparer" class="">Local Preparer</th>
                    <th scope="l_approver" class="">Local Approver</th>
                    <th scope="r_approver" class="">Regional Approver</th>
                    <th scope="manager" class="">Manager</th>
                  </tr>
                </thead>
                <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                  <tr v-for="(v, k) in bulksubmitrufiledata" :key="k" class="">
                    <template v-for="(vv, kk) in bulksubmitrufilecolumns">
                      <td :key="kk" class="">
                        <div v-if="['l_preparer', 'l_approver', 'r_approver', 'manager'].indexOf(vv) > -1">
                          <!-- {{(v[vv] || [])}}
                          {{bulksubmitrufileerror[k][vv][vk]}} -->
                          <template v-for="(nv, nk) in (v[vv] || [])">
                            <v-tooltip v-if="bulksubmitrufileerror[k][vv]?.[nk]" bottom content-class="tooltip-bottom"
                              :key="nk+'error'">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip small label outlined v-bind="attrs" v-on="on"
                                  class="error--text font-weight-bold mr-1 mb-1"><v-icon left>mdi-account</v-icon>{{nv
                                  || 'Error'}}</v-chip>
                              </template>
                              <span>{{ bulksubmitrufileerror[k][vv][nk] }}</span>
                            </v-tooltip>
                            <v-tooltip v-else-if="bulksubmitrufilewarn[k][vv]?.[nk]" bottom content-class="tooltip-bottom"
                              :key="nk+'warn'">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip small label outlined v-bind="attrs" v-on="on"
                                  class="warning--text font-weight-bold mr-1 mb-1"><v-icon left>mdi-account</v-icon>{{nv
                                  || 'Warn'}}</v-chip>
                              </template>
                              <span>{{ bulksubmitrufilewarn[k][vv][nk] }}</span>
                            </v-tooltip>
                            <v-chip small label outlined v-else class="mr-1 mb-1" :key="nk"><v-icon
                                left>mdi-account</v-icon>{{ nv || '' }}</v-chip>
                          </template>
                        </div>
                        <v-tooltip v-else-if="bulksubmitrufileerror[k][vv]" bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="error--text font-weight-bold">{{v[vv] ||
                              ''}}</span>
                          </template>
                          <span>{{ bulksubmitrufileerror[k][vv] }}</span>
                        </v-tooltip>
                        <div v-else>{{ v[vv] || '' }}</div>
                      </td>
                    </template>
                  </tr>
                </v-slide-y-transition>
              </v-simple-table>
            </div>
            <div v-else class="grey--text pa-6 text-center title">
              Upload template file
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <div v-if="emptyRuCode" class="error--text font-weight-bold">
            Ru code can't be empty.
          </div>
          <div v-if="bulksubmitrufileerrorflag" class="px-2 error--text font-weight-bold">
            Please provide all valid details
          </div>
          <!-- <span v-else-if="bulksubmitrufiledata.length > 0" class="caption">*Required</span> -->
          <v-spacer></v-spacer>
          <v-btn v-if="bulksubmitrufiledata.length > 0 && !bulksubmitrufileerrorflag && !emptyRuCode"
            @click="updateUserAccessChange()" color="primary" small class="ml-2 shadow-off">Proceed to Upload</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="allaccessdownloaddialog" :loading="allaccessdownloadloading"
        heading="All access data download" width="600">
        <template v-slot:body>
          <div>
            <div class="d-flex align-center">
              <v-spacer />
              <v-btn small color="success" @click="downloadAllAccess()">Generate latest</v-btn>
            </div>
            <div v-if="allaccessdownloadlist.length === 0" class="title grey--text text--lighten-1 ma-4 text-center">No
              downloads</div>
            <div v-else>
              <div class="mb-2 font-weight-bold">Previous downloads</div>
              <div class="hoverable" v-for="(v,k) in allaccessdownloadlist" :key="k">
                <div class="d-flex align-center px-2 py-1">
                  <div>{{$nova.formatDateTime(v.created_at)}}</div>
                  <v-spacer />
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="v.stage===1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on"
                        @click.stop="downloadAllAccess(v._id)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-chip small v-if="v.stage === 1" color="success" label>Ready</v-chip>
                  <v-chip small v-else-if="v.stage === 0 || v.stage === 4" color="info" label>Generating</v-chip>
                  <v-chip small v-else color="error" label>Error</v-chip>
                </div>
                <v-divider />
              </div>
            </div>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="faqdialog" heading="Frequently asked question" width=800 :loading="loading">
        <template v-slot:body>
          <h2>Question:&nbsp; What is Access management? How can I update this?</h2>
          <span><b>Answer:</b>&nbsp; Access management helps in role assignments of users for a specific reporting unit.
            For a detailed explanation and step by step instruction, please refer the section in the user manual on
            "Access Management"</span>
          <v-divider :thickness="2"></v-divider>
          <br>
          <h2>Question:&nbsp; How do I check my role and Reporting Unit, why can't I perform any function in the tool,
            and what should I do if I haven't been assigned roles or have 'View only' access?</h2>
          <span>
            <b>Answer:</b>
            <br>
            1. Check if the User has access to the reporting unit as per BFC which drives the user role allocation in
            the Starlight Application.. Refresh the screen and try in 5 minutes
            <br>
            2. Reach out to the Tool admin preparer to assign the relevant roles
            <br>
            3. Reach out to the Tool admin approver to approve the roles assigned
            <br>
            4. If issues persist, reach out to the Consark Support Team with the issue and screenshot if any
          </span>
          <v-divider :thickness="2"></v-divider>
          <br>
          <h2>Question:&nbsp; What should I do if I encounter an error message during a Bulk RU level upload or if I get
            a 'User ID not found' error?</h2>
          <span>
            <b>Answer:</b>&nbsp; Please follow the following steps:
            <br>
            1. Ensure the email ID's entered are in lowercase
            <br>
            2. Refresh the screen and try in 10 minutes
            <br>
            3. If issues persist, reach out to the Consark Support Team with the issue and screenshot if any
          </span>
          <v-divider :thickness="2"></v-divider>
          <br>
          <h2>Question:&nbsp; What should I do if I am unable to add/ remove users or need to remove user assignments?
          </h2>
          <span>
            <b>Answer:</b>&nbsp;
            <br>
            1.
            <br>
            &nbsp; a. Refresh the screen and try in 5 minutes
            <br>
            &nbsp; b. If issues persist, reach out to the Consark Support Team with the issue and screenshot if any
            <br>
            &nbsp; c. In case of user removal, confirmation on the user removal from the Tool Admin Approver to be
            received post which it will be updated.
            <br>
            2.Tool admin approver rejection is the only way you could reverse the change
          </span>
          <v-divider :thickness="2"></v-divider>
          <br>
          <h2>Question:&nbsp; Can I add users to multiple reporting units at one time ?</h2>
          <span><b>Answer:</b>&nbsp; <b>Yes</b>, this is possible using the option "Bulk RU Level Upload" under the
            Access
            Management. Refer the section in the User Manual on " Access Management "</span>
        </template>
      </lb-dialog>

    </div>
  </div>
</template>

<script>
import IndividualApprovalsList from "./IndividualApprovalsList.vue";
// import cfilter from './Filter'
export default {
  name: 'reportingunits',
  // props: ['props'],
  data: function(){
    return {
      right: "commentary_reportingunit",
      loading: false,
      data: [],
      userlist: {},
      userlistarr: {},
      useremailidmap: {},
      addeditrudialog: false,
      addeditruloading: false,
      addeditrudata: {},
      userdatachange_submitter: [],
      userdatachange_approver: [],
      userdatachange_regional_approver: [],
      userdatachange_manager: [],
      addeditruerror: {},

      adduserdialog: false,
      addusertype: '',
      adduservalue: '',
      rucodenamemap: {},
      rucodeidmap: {},
      ruidcodemap: {},

      bulksubmitrudialog: false,
      bulksubmitruloading: false,
      bulksubmitrufileerrorflag: false,
      bulksubmitrufile: null,
      bulksubmitrufiledata: [],
      bulksubmitrufileerror: [],
      bulksubmitrufilewarn: [],
      bulksubmitrufilecolumns: ["rucode", "runame", "l_preparer", "l_approver", "r_approver", "manager"],
      fileprocessing: {},

      bulksubmit: false,
      filesubmit: false,
      pendingrequestlist: [],
      pendingdialog: false,
      pendingloading: false,
      formatedData: [],
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
        },
        {
          value: "code",
          text: "RU Code",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "network",
          text: "Network",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "region",
          text: "Region",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "country",
          text: "Country",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "currency",
          text: "Currency",
          sortable: true,
          filterable: true,
          lock: false,
          visible: false,
        },
        {
          value: "submitterFew",
          text: "Local Preparer(s)",
          sortable: false,
          filterable: false,
          lock: true,
          alignment: "text-center",
        },
        {
          value: "approverFew",
          text: "Local Approver(s)",
          sortable: false,
          filterable: false,
          lock: true,
          alignment: "text-center",
        },
        {
          value: "regional_approverFew",
          text: "Regional Approver",
          sortable: false,
          filterable: false,
          lock: true,
          alignment: "text-center",
        },
        {
          value: "managerFew",
          text: "Manager",
          sortable: false,
          filterable: false,
          lock: true,
          alignment: "text-center",
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],

      // selectedrole: "",
      // role: [],
      // rolelist: {},
      // selectedyear: "",
      // year: [],
      // yearlist: {},
      // selectedperiod: "",
      // period: [],
      // perioddata: [],
      // periodlist: {},
      // selectednetwork: "",
      // network: [],
      // networkdata: [],
      // networklist: {},
      // selectedregion: "",
      // region: [],
      // regiondata: [],
      // regionlist: {},
      // selectedcountry: "",
      // country: [],
      // countrydata: [],
      // countrylist: {},
      // selectedreportingunit: "",
      // reportingunit: [],
      // reportingunitdata: [],
      // reportingunitlist: {},
      allaccessdownloaddialog: false,
      allaccessdownloadloading: false,
      allaccessdownloadlist: [],
      allRUDownloadPeriod: null,
      faqdialog:false,
      emptyRuCode:false,
      ruUpdateErrorData:[],
      ruidDataMap: {},
    }
  },
  components: {
    IndividualApprovalsList
    // cfilter
  },
  activated() {
    this.refreshData();
  },
  created() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.getData();
    },
    setPendingLoading(v) {
      this.pendingloading = v;
    },
    getData() {
      this.loading = true;
      this.pendingrequestlist = [];
      this.axios.post("/v2/varianceanalysis/reportingunit/getformdata").then(dt => {
        if(dt.data.status === "success") {
          let ndt = dt.data.data[0] || {};
          this.userlist = {};
          this.userlistarr = ndt.users || [];
          let colors = this.$nova.getColors(this.userlistarr.length, 0.2);
          this.useremailidmap = {};
          for (let i = 0; i < this.userlistarr.length; i++) {
            const el = this.userlistarr[i];
            this.useremailidmap[el.email] = el._id;
            el.newdisplay = el.email;
            if(el.name) el.newdisplay + " - " + el.name;
            this.userlistarr[i].newdisplay = el.newdisplay;
            this.userlist[el._id] = el;
            this.userlist[el._id].color = colors[i];
          }
          return this.axios.post("/v2/varianceanalysis/reportingunit/getalllist", {filter: {}});
        }
        else throw new Error (dt.data.message || "Error reading data");
      }).then(dt => {
        if(dt.data.status === "success") {
          this.data = dt.data.data;
          this.formateData();
          this.getPendingApprovalData();
        }
        else throw new Error (dt.data.message || "Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    getPendingApprovalData() {
      this.pendingrequestlist = [];
      this.axios.post("/v2/varianceanalysis/reportingunit/getpendingaccessrequest").then(dt => {
        if(dt.data.status === "success") {
          if(dt.data.data.length > 0) {
            this.pendingrequestlist = dt.data.data;
          }
        }
        else throw new Error (dt.data.message || "Error reading pending request data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
      })
    },
    formateData() {
      let ArrayTemp = [];
      this.rucodenamemap = {};
      this.rucodeidmap = {};
      this.data.forEach((i, k) => {
        this.rucodenamemap[i.code] = i.name;
        this.rucodeidmap[i.code] = i._id;
        this.ruidcodemap[i._id] = i.code;
        this.ruidDataMap[i._id] = i;
        ArrayTemp.push({
          __key: k,
          __formatedkey: k,
          _id: i._id,
          indexno: k + 1,
          code: i.code,
          name: i.name,
          network: (i.network_detail || {}).name || "NA",
          region: (i.region_detail || {}).name || "NA",
          country: (i.country_detail || {}).name || "NA",
          submitterids: i.submitter || [],
          submitter: this.getCodeName(i.submitter || []),
          submitterFew: this.getCodeName(i.submitter || [], 2),
          __submitterplus: ((i.submitter || []).length - 2),
          approverids: i.approver || [],
          approver: this.getCodeName(i.approver || []),
          approverFew: this.getCodeName(i.approver || [], 2),
          __approverplus: ((i.approver || []).length - 2),
          regional_approverids: i.regional_approver || [],
          regional_approver: this.getCodeName(i.regional_approver || []),
          regional_approverFew: this.getCodeName(i.regional_approver || [], 2),
          __regional_approverplus: ((i.regional_approver || []).length - 2),
          managerids: i.manager || [],
          manager: this.getCodeName(i.manager || []),
          managerFew: this.getCodeName(i.manager || [], 2),
          __managerplus: ((i.manager || []).length - 2),
          currency: i.currency,
          created_at: this.toISOConverter(i.created_at) + " IST",
          updated_at: this.toISOConverter(i.updated_at) + " IST",
        });
      });
      // console.log(ArrayTemp)
      this.formatedData = ArrayTemp;
    },
    getCodeName(dt, limit){
      return dt.slice(0, limit || (dt || []).length).map(el => {return {
        _id: el,
        code: (((this.userlist[el] || {}).email || "UN").split("") || []).slice(0, 2).map((word) => word[0].toUpperCase()).join(""),
        name: (this.userlist[el] || {}).email || "Unknown User",
        color: (this.userlist[el] || {}).color || 'rgba(0,0,0,0.2)'
      }})
    },
    editData(v, type, ru) {
      this.bulksubmitrufiledata = [];
      this.bulksubmitrufileerrorflag = false;
      this.emptyRuCode=false,
      // this.editablegls = [];
      // this.ruleveledit.local = false;
      // this.ruleveledit.regional = false;
      // this.ruleveledit.enable = false;
      this.userdatachange_submitter = [];
      this.userdatachange_approver = [];
      this.userdatachange_regional_approver = [];
      this.userdatachange_manager = [];
      this.ruUpdateErrorData = [];
      if(type === "bulk") {
        this.bulksubmit = true;
        this.filesubmit = false;
        this.addeditrudata={};
        this.addeditrudata.__type = type;
        this.addeditruerror={};

      }
      else if(type === "single") {
        this.addeditrudata=this.$nova.deepCopyJson(v);
        this.addeditrudata.__originalsubmitter = [...(this.addeditrudata.submitter || [])];
        this.addeditrudata.__originalapprover = [...(this.addeditrudata.approver || [])];
        this.addeditrudata.__originalregional_approver = [...(this.addeditrudata.regional_approver || [])];
        this.addeditrudata.__originalmanager = [...(this.addeditrudata.manager || [])];
        this.addeditrudata.__type = type;
        this.addeditruerror={};
        this.bulksubmit = false;
        this.filesubmit = false;
      }
      else if(type === "file") {
        this.bulksubmit = false;
        this.filesubmit = true;
        this.addeditrudata={};
        this.addeditrudata.__type = type;
        this.addeditruerror={};
        this.bulksubmitrudialog = true;
      }
      if(type === "bulk" || type === "single"){
        this.addeditrudata._id = ru;
        this.addeditrudialog=true;
        // if(ru instanceof Array) this.formattedGLData = this.generateFormattedGLList({});
        // else this.getRUData(ru);
      }
    },
    getDownloadAllAccesslist() {
      this.allaccessdownloaddialog = true;
      this.allaccessdownloadloading = true;
      let url = "/v2/varianceanalysis/reportingunit/downloadallaccessexcellist/";
      this.axios.post(url, {}).then(dt => {
        if(dt.data.status === "success") {
          this.allaccessdownloadlist = dt.data.data;
        }
        else throw new Error (dt.data.message || "Error loading download list");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.allaccessdownloadloading = false;
      })
    },
    downloadAllAccess(id) {
      this.allaccessdownloadloading = true;
      let url = "/v2/varianceanalysis/reportingunit/downloadallaccessexcel/";
      if(id) url = url + id;
      // console.log(this.selectedcommentary);
      this.axios.post(url, {filter: {}}).then(dt => {
        if(dt.data.status === "success") {
          this.getDownloadAllAccesslist();
          console.log(dt.data);
        }
        else throw new Error (dt.data.message || "Error generating download");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.allaccessdownloadloading = false;
      })
    },   
    updateUserAccessChange() {
      this.addeditruloading = true;
      let newdata = {
        reportingunit: this.addeditrudata._id || '',
      };
      if(this.addeditrudata.__type === 'single' || this.addeditrudata.__type === 'bulk'){
        newdata.type = "accesschange";
        if(this.bulksubmit) {
          newdata.bulk = true;
          newdata.type = "bulkaccesschange";
        }
        let add = {};
        let remove = {};
        let usercngdt = {
          submitter: this.userdatachange_submitter,
          approver: this.userdatachange_approver,
          regional_approver: this.userdatachange_regional_approver,
          manager: this.userdatachange_manager,
        }
        for (const k in usercngdt) {
          if (Object.hasOwnProperty.call(usercngdt, k)) {
            const el = this["userdatachange_"+k];
            for (const i of el) {
              if(i.action === "remove"){
                if(i.user?._id){
                  remove[k] = remove[k] || [];
                  remove[k].push(i.user._id);
                }
              } else if(i.action === "add"){
                if(i.user?._id){
                  add[k] = add[k] || [];
                  add[k].push(i.user._id);
                }
              }
            }
          }
        }
        for (const k in add) {
          if (Object.hasOwnProperty.call(add, k)) {
            const el = add[k];
            if(el.length === 0) delete add[k];
          }
        }
        for (const k in remove) {
          if (Object.hasOwnProperty.call(remove, k)) {
            const el = remove[k];
            if(el.length === 0) delete remove[k];
          }
        }
        if(Object.keys(add).length > 0) newdata.adduser = add;
        if(Object.keys(remove).length > 0) newdata.removeuser = remove;
      }
      else if(this.addeditrudata.__type === "file") {
        this.bulksubmitruloading = true;
        newdata.type = "fileaccesschange";
        newdata.bulk = true;
        let ndt = [];
        newdata.reportingunit = [];
        // console.log(this.bulksubmitrufiledata);
        for (const i of this.bulksubmitrufiledata) {
          let td = {};
          td.rucode = this.rucodeidmap[i.rucode];
          newdata.reportingunit.push(td.rucode);
          if(Object.prototype.hasOwnProperty.call(i, "l_preparer_ids")) td.preparer = i.l_preparer_ids || undefined;
          if(Object.prototype.hasOwnProperty.call(i, "l_approver_ids")) td.approver = i.l_approver_ids || undefined;
          if(Object.prototype.hasOwnProperty.call(i, "r_approver_ids")) td.regional_approver = i.r_approver_ids || undefined;
          if(Object.prototype.hasOwnProperty.call(i, "manager_ids")) td.manager = i.manager_ids || undefined;
          ndt.push(td);
        }
        newdata.users = ndt;
      }
      this.axios.post("/v2/varianceanalysis/reportingunit/saveaccessupdateforapproval", {data: newdata}).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Update request raised for approval");
          this.addeditrudialog = false;
          this.bulksubmitrudialog = false;
        }else {
          let data =  dt.data.data || []
          
          if(data.length>0){
            if (this.addeditrudata.__type === "single" || this.addeditrudata.__type === 'bulk'){
              this.ruUpdateErrorData = [];
              if(data.length>0){
                this.ruUpdateErrorData = data || [];
              }

            }
            else if (this.addeditrudata.__type === "file"){
              this.bulksubmitrufileerrorflag = true
              data.forEach((ruErrorData) => {
                let index = this.bulksubmitrufiledata.findIndex(item => item.ruid === ruErrorData.rucode);
                console.log(data)
                if (index !== -1) {
                  let ruUploadedData = this.bulksubmitrufiledata[index];

                  ruErrorData.userids.forEach((user) => {
                    const roleTypes = ['l_preparer', 'l_approver', 'r_approver', 'manager'];
                    const roleIds = ['l_preparer_ids', 'l_approver_ids', 'r_approver_ids', 'manager_ids'];

                    roleTypes.forEach((role, i) => {
                      let userIndex = (ruUploadedData[roleIds[i]] || []).indexOf(user);
                      if (userIndex !== -1) {
                        if (!Object.prototype.hasOwnProperty.call(this.bulksubmitrufileerror[index], role)) {
                          this.bulksubmitrufileerror[index][role] = [];
                        }
                        while (this.bulksubmitrufileerror[index][role].length <= userIndex) {
                          this.bulksubmitrufileerror[index][role].push("");
                        }

                        this.bulksubmitrufileerror[index][role][userIndex] = "User doesn't have access to this RU";
                      }
                    });
                  });
                }
              });
            }
            this.addeditruloading = false;
          }
          throw new Error (dt.data.message || "Error raising change request");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addeditruloading = false;
        this.bulksubmitruloading = false;
        this.getPendingApprovalData();
      })
    },
    // getRUData(id) {
    //   this.addeditruloading = true;
    //   this.addeditrudialog = true;
    //   this.axios.post("/v2/varianceanalysis/materiality/getmy/"+id).then(dt => {
    //     if(dt.data.status === "success") {
    //       let ndt = {};
    //       for (const i of dt.data.data) {
    //         ndt[i._id] = i;
    //       }
    //       this.formattedGLData = this.generateFormattedGLList(ndt);
    //     }
    //     else throw new Error ("Error reading materiality data");
    //   }).catch(err => {
    //     this.$store.commit("sbError", err.message || err || "Unknown error!");
    //     console.log(err);
    //   }).finally(() => {
    //     this.addeditruloading = false;
    //   })
    // },
    // editData(v) {
    //   this.addeditrudialog=true;
    //   this.addeditrudata=this.$nova.deepCopyJson(v);
    //   this.addeditrudata.__originalsubmitter = [...(this.addeditrudata.submitter || [])];
    //   this.addeditrudata.__originalapprover = [...(this.addeditrudata.approver || [])];
    //   this.addeditrudata.__originalmanager = [...(this.addeditrudata.manager || [])];
    //   this.addeditruerror={};
    //   if(this.addeditrudata.control_thresholds_percent) this.addeditrudata.control_thresholds_percent = this.addeditrudata.control_thresholds_percent*100;
    //   if(this.addeditrudata.management_thresholds_percent) this.addeditrudata.management_thresholds_percent = this.addeditrudata.management_thresholds_percent*100;
    // },
    // updateUserAccessChange() {
    //   this.addeditruloading = true;
    //   let newdata = this.userdatachange || {};
    //   this.axios.post("/v2/varianceanalysis/reportingunit/updateuseraccesschange/"+this.addeditrudata._id, {data: [newdata]}).then(dt => {
    //     if(dt.data.status === "success") {
    //       this.$store.commit("sbSuccess", "Update request raised for approval");
    //       this.addeditrudialog = false;
    //     }else throw new Error (dt.data.message || "Error raising change request");
    //   }).catch(err => {
    //     this.$store.commit("sbError", err.message || err || "Unknown error!");
    //     console.log(err);
    //   }).finally(() => {
    //     this.addeditruloading = false;
    //   })
    // },
    // bulkUpdateAccess() {
    //   this.bulkchangeloading = true;
    //   let newdata = {type: this.addusertype, data: this.bulkchange};
    //   let fltr = {count: this.filtereddata.length};
    //   if(this.selectednetwork !== "all") fltr.network = this.selectednetwork;
    //   if(this.selectedregion !== "all") fltr.region = this.selectedregion;
    //   if(this.selectedcountry !== "all") fltr.country = this.selectedcountry;
    //   this.axios.post("/v2/varianceanalysis/reportingunit/updatebulkuseraccesschange/", {data: newdata, filter: fltr}).then(dt => {
    //     if(dt.data.status === "success") {
    //       this.$store.commit("sbSuccess", "Bulk update request raised for approval");
    //       this.bulkchangedialog = false;
    //     }else throw new Error (dt.data.message || "Error raising bulk change request");
    //   }).catch(err => {
    //     this.$store.commit("sbError", err.message || err || "Unknown error!");
    //     console.log(err);
    //   }).finally(() => {
    //     this.bulkchangeloading = false;
    //   })
    // },
    removeAccess(k, type) {
      this["userdatachange_"+type] = this["userdatachange_"+type] || [];
      this["userdatachange_"+type].push({action: 'remove', type: type, user: this.addeditrudata[type][k]});
      this.addeditrudata[type].splice(k,1);
    },
    addUser() {
      let userid = this.adduservalue;
      let type = this.addusertype;
      this["userdatachange_"+type] = this["userdatachange_"+type] || [];
      this["userdatachange_"+type].push({action: 'add', type: type, user: this.getCodeName([userid])[0]});
      this.addeditrudata.__changeadd = this.addeditrudata.__changeadd || [];
      this.addeditrudata.__changeadd.push(userid);
      this.addeditrudata[type] = this.addeditrudata[type] || [];
      this.addeditrudata[type].push({});
      this.addeditrudata[type].pop();
      this.addusertype = '';
      this.adduservalue = '';
      this.adduserdialog = false;
    },
    undoChange(id, k, type, action){
      if(action === "remove")this.addeditrudata[type].push(this.getCodeName([id])[0]);
      else if(action === "add"){
        let ak = (this.addeditrudata.__changeadd || []).indexOf(id);
        if(ak > -1) this.addeditrudata.__changeadd.splice(ak,1);
      }
      (this["userdatachange_"+type] || []).splice(k,1);
      if(this["userdatachange_"+type].length === 0) delete this["userdatachange_"+type];
      this.sanitizeChangeList();
    },
    sanitizeChangeList(){
      let alluserids = [...(this.addeditrudata["submitter"] || []).map(d => d._id), ...(this.addeditrudata["approver"] || []).map(d => d._id), ...(this.addeditrudata["regional_approver"] || []).map(d => d._id), ...(this.addeditrudata["manager"] || []).map(d => d._id)];
      this["userdatachange_"+"submitter"] = (this["userdatachange_"+"submitter"] || []).filter(dt => (alluserids.indexOf(dt.user?._id) === -1)?true:false);
      this["userdatachange_"+"approver"] = (this["userdatachange_"+"approver"] || []).filter(dt => (alluserids.indexOf(dt.user?._id) === -1)?true:false);
      this["userdatachange_"+"regional_approver"] = (this["userdatachange_"+"regional_approver"] || []).filter(dt => (alluserids.indexOf(dt.user?._id) === -1)?true:false);
      this["userdatachange_"+"manager"] = (this["userdatachange_"+"manager"] || []).filter(dt => (alluserids.indexOf(dt.user?._id) === -1)?true:false);
      this.addeditrudata.__changeadd = (this.addeditrudata.__changeadd || []).filter(dt => (alluserids.indexOf(dt) === -1)?true:false);
    },
    readBulkSubmitFile(key) {
      this.emptyRuCode=false
      let errorMessages = {
        "existingaspreparer":"Already exists as a Local Preparer",
        "existingasapprover":"Already exists as a Local Approver",
        "existingasregionalapprover":"Already exists as a Regional Approver",
        "existingasmanager":"Already exists as a Manager",

      }
      let file = this.bulksubmitrufile;
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        let headings = ['RU Code', 'Name of Reporting Unit', 'Local preparer(s)', 'Local Approver(s)', 'Regional Approver(s)', 'Manager(s)'];
        // console.log(file);
        this.bulksubmitruloading = true;
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {
            let filedata = [];
            let errordata = [];
            let warning_data = [];
            this.bulksubmitrufileerrorflag = false;
            let visitedRUCodes=[];
            for (let i = 0; i < dt.length; i++) {
              if (i > 0) {
                const el = dt[i];
                if (el.join("") !== "") {
                    let errors = {};
                    let warn_data = {};
                    let startindex = 2;
                    let rowdata = {
                      "rucode": el[0]?.trim(),
                      "ruid": this.rucodeidmap[el[0]?.trim()] || 'NA',
                      "runame": this.rucodenamemap[el[0].trim()]|| el[1] || "",
                      'l_preparer':[],
                      'l_approver': [],
                      'r_approver': [],
                    };
                    if(el[0]?.trim() === undefined)
                    {
                      this.emptyRuCode=true
                    }
                    
                    if(visitedRUCodes.indexOf(rowdata.rucode) > -1) errors["rucode"] = "Duplicate code found" 
                    if (!this.rucodenamemap[rowdata.rucode]) errors["rucode"] = "RU Code not found";
                    else visitedRUCodes.push(rowdata.rucode)                    
                    let filterdata = this.formatedData.filter((x) => x.code === rowdata.rucode);
                    if(el[startindex + 0]) {
                        let terrors = [];
                        let tids = [];
                        let warn_users = [];
                        let temails = el[startindex + 0].split(",");
                        temails = temails.map(email => email.trim());
                        for (let email of temails) {
                            if (this.useremailidmap[email]) {
                                let isPreparer = filterdata?.[0]?.submitter?.some(item => item.name === email);
                                let isApprover = filterdata?.[0]?.approver?.some(item => item.name === email);
                                let isRegionalApprover = filterdata?.[0]?.regional_approver?.some(item => item.name === email);
                                let isManager = filterdata?.[0]?.manager?.some(item => item.name === email);
                                if (!isPreparer && tids.indexOf(this.useremailidmap[email]) === -1) {
                                    // If the email is not found in any role, push it to tids array
                                    tids.push(this.useremailidmap[email]);
                                    if (isApprover) {
                                        warn_users.push(errorMessages.existingasapprover);
                                    } else if (isRegionalApprover) {
                                        warn_users.push(errorMessages.existingasregionalapprover);
                                    } else if (isManager) {
                                        warn_users.push(errorMessages.existingasmanager);
                                    }
                                    terrors.push("");
                                } else {
                                  // If the email is found in any role, push the corresponding error message
                                  if (isPreparer) {
                                    terrors.push(errorMessages.existingaspreparer);
                                  } else if(tids.indexOf(this.useremailidmap[email]) > -1){
                                    terrors.push("Duplicate email found");
                                  }
                                }
                            } else {
                                terrors.push("Email not found");
                            }
                        }
                        rowdata.l_preparer = temails;
                        rowdata.l_preparer_ids = tids;
                        if (terrors.join("") !== "") errors.l_preparer = terrors;
                        if (warn_users.join("") !== "") warn_data.l_preparer = warn_users;
                    }
                    if(el[startindex + 1]){
                        let terrors = [];
                        let warn_users = [];
                        let tids = [];
                        let temails = el[startindex + 1].split(",");

                        temails = temails.map(email => email.trim());
                        for (let email of temails) {
                            if (this.useremailidmap[email]) {
                                let isPreparer = filterdata?.[0]?.submitter?.some(item => item.name === email);
                                let isApprover = filterdata?.[0]?.approver?.some(item => item.name === email);
                                let isRegionalApprover = filterdata?.[0]?.regional_approver?.some(item => item.name === email);
                                let isManager = filterdata?.[0]?.manager?.some(item => item.name === email);
                                if ((rowdata.l_preparer || []).indexOf(i) > -1) {
                                    terrors.push("Local Approver and Local Preparer can't be the same");
                                } else if (!isApprover && rowdata.l_preparer.indexOf(email) === -1 && tids.indexOf(this.useremailidmap[email]) === -1) {
                                  tids.push(this.useremailidmap[email]);
                                  if (isPreparer) {
                                      warn_users.push(errorMessages.existingaspreparer);
                                  } else if (isRegionalApprover) {
                                      warn_users.push(errorMessages.existingasregionalapprover);
                                  } else if (isManager) {
                                      warn_users.push(errorMessages.existingasmanager);
                                  }
                                  terrors.push("");
                                } else {
                                    if (isApprover) {
                                      terrors.push(errorMessages.existingasapprover);
                                    } else if (rowdata.l_preparer.indexOf(email) > -1){
                                      terrors.push("Local Preparer and Local Approver can't be the same");
                                    } else if(tids.indexOf(this.useremailidmap[email]) !== -1){
                                      terrors.push("Duplicate email found");
                                    }
                                }
                            } else {
                                terrors.push("Email not found");
                            }
                        }
                        rowdata.l_approver = temails;
                        rowdata.l_approver_ids = tids;
                        if (terrors.join("") !== "") errors.l_approver = terrors;
                        if (warn_users.join("") !== "") warn_data.l_approver = warn_users;
                    }
                    if(el[startindex + 2] ){
                        let terrors = [];
                        let warn_users = [];
                        let tids = [];
                        let temails = el[startindex + 2].split(",");
                        temails = temails.map(email => email.trim());

                        for (let email of temails) {
                            if (this.useremailidmap[email]) {
                                let isPreparer = filterdata?.[0]?.submitter?.some(item => item.name === email);
                                let isApprover = filterdata?.[0]?.approver?.some(item => item.name === email);
                                let isRegionalApprover = filterdata?.[0]?.regional_approver?.some(item => item.name === email);
                                let isManager = filterdata?.[0]?.manager?.some(item => item.name === email);
                                if ((rowdata.l_preparer || []).indexOf(i) > -1) {
                                    terrors.push("Regional Approver and Local Preparer can't be the same");
                                } else if ((rowdata.l_approver || []).indexOf(i) > -1) {
                                    terrors.push("Regional Approver and Local Approver can't be the same");
                                } else if (!isRegionalApprover && rowdata.l_preparer.indexOf(email) === -1  && rowdata.l_approver.indexOf(email) === -1 && tids.indexOf(this.useremailidmap[email]) === -1) {
                                    tids.push(this.useremailidmap[email]);
                                    if (isPreparer) {
                                        warn_users.push(errorMessages.existingaspreparer);
                                    } else if (isApprover) {
                                        warn_users.push(errorMessages.existingasapprover);
                                    } else if (isManager) {
                                        warn_users.push(errorMessages.existingasmanager);
                                    }
                                    terrors.push("");
                                } else {
                                  if (isRegionalApprover) {
                                    terrors.push(errorMessages.existingasregionalapprover);
                                  } else if (rowdata.l_preparer.indexOf(email) > -1){
                                    terrors.push("Local Preparer and Regional Approver can't be the same");
                                  } else if (rowdata.l_approver.indexOf(email) > -1){
                                    terrors.push("Local Approver and Regional Approver can't be the same");
                                  } else if(tids.indexOf(this.useremailidmap[email]) !== -1){
                                    terrors.push("Duplicate email found");
                                  }
                                }
                            } else {
                                terrors.push("Email not found");
                            }
                        }

                        rowdata.r_approver = temails;
                        rowdata.r_approver_ids = tids;
                        if (terrors.join("") !== "") errors.r_approver = terrors;
                        if (warn_users.join("") !== "") warn_data.r_approver = warn_users;
                    }
                    if(el[startindex + 3]){
                        let terrors = [];
                        let warn_users = [];
                        let tids = [];
                        let temails = el[startindex + 3].split(",");
                        temails = temails.map(email => email.trim());
                        for (let email of temails) {
                            let isPreparer = filterdata?.[0]?.submitter?.some(item => item.name === email);
                            let isApprover = filterdata?.[0]?.approver?.some(item => item.name === email);
                            let isRegionalApprover = filterdata?.[0]?.regional_approver?.some(item => item.name === email);
                            let isManager = filterdata?.[0]?.manager?.some(item => item.name === email);
                            if (this.useremailidmap[email]) {
                                if ((rowdata.l_preparer || []).indexOf(i) > -1) {
                                    warn_users.push("Manager and Local Preparer can't be the same");
                                } else if ((rowdata.l_approver || []).indexOf(i) > -1) {
                                    warn_users.push("Manager and Local Approver can't be the same");
                                } else if ((rowdata.r_approver || []).indexOf(i) > -1) {
                                    warn_users.push("Manager and Regional Approver can't be the same");
                                } else if (!isManager && rowdata.l_preparer.indexOf(email) === -1 && rowdata.l_approver.indexOf(email) === -1 && rowdata.r_approver.indexOf(email) === -1 && tids.indexOf(this.useremailidmap[email]) === -1) {
                                    tids.push(this.useremailidmap[email]);
                                    if (isPreparer) {
                                        warn_users.push(errorMessages.existingaspreparer);
                                    } else if (isApprover) {
                                        warn_users.push(errorMessages.existingasapprover);
                                    } else if (isRegionalApprover) {
                                        warn_users.push(errorMessages.existingasregionalapprover);
                                    } 
                                    terrors.push("");
                                } else {
                                  if (isManager) {
                                    terrors.push(errorMessages.existingasmanager);
                                  } else if (rowdata.l_preparer.indexOf(email) > -1){
                                    terrors.push("Manager and Local Preparer can't be the same");
                                  } else if (rowdata.l_approver.indexOf(email) > -1){
                                    terrors.push("Manager and Local Approver can't be the same");
                                  } else if (rowdata.r_approver.indexOf(email) > -1){
                                    terrors.push("Manager and Regional Approver can't be the same");
                                  } else if(tids.indexOf(this.useremailidmap[email]) !== -1){
                                    terrors.push("Duplicate email found");
                                  }
                                }
                            } else {
                                terrors.push("Email not found");
                            }
                        }
                        rowdata.manager = temails;
                        rowdata.manager_ids = tids;
                        if (terrors.join("") !== "") errors.manager = terrors;
                        if (warn_users.join("") !== "") warn_data.manager = warn_users;
                    }
                    if([0, 1, 2, 3].every(i => typeof el[startindex + i] === 'undefined')){
                      errors.rucode = "No users assigned"
                    }
                    if (Object.keys(errors).length > 0 || [0, 1, 2, 3].every(i => typeof el[startindex + i] === 'undefined')) {
                        this.bulksubmitrufileerrorflag = true;
                    }
                    for (const key in rowdata) {
                      if (rowdata[key].length === 0) {
                          delete rowdata[key];
                      }
                    }
                    filedata.push(rowdata);
                    errordata.push(errors);
                    warning_data.push(warn_data);
                }
            }
          }
            this.bulksubmitrufiledata = filedata;
            this.bulksubmitrufileerror = errordata;
            this.bulksubmitrufilewarn = warning_data;
          } else throw Error("Columns '"+headings.join("','")+"' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.bulksubmitrufile = null;
          this.fileprocessing[key] = 0;
          this.bulksubmitruloading = false;
        });
      }
    },
    toISOConverter(rawDate) {
      try {
        const date = new Date(rawDate);

        // Extract date components
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // January is 0
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Format the date as desired (e.g., YYYY-MM-DD HH:mm:ss)
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

        return formattedDate
      } catch (error) {
        console.error("Error:", error.message);
      }

    }
  },
  computed: {
    filteredUserListArr: function(){
      let rdt = [];
      if(this.addusertype){
        
        for (const k in this.userlistarr) {
          if (Object.hasOwnProperty.call(this.userlistarr, k)) {
            const el = this.userlistarr[k];
            let pushflag = true;
            let remsubids = (this["userdatachange_"+"submitter"] || []).map(dt => dt.user?._id);
            let remappids = (this["userdatachange_"+"approver"] || []).map(dt => dt.user?._id);
            let remrappids = (this["userdatachange_"+"regional_approver"] || []).map(dt => dt.user?._id);
            let remmanids = (this["userdatachange_"+"manager"] || []).map(dt => dt.user?._id);
            if((this.addeditrudata["__original"+this.addusertype] || []).indexOf(el._id) > -1) pushflag = false;
            
            if((this.addeditrudata["submitterids"] || []).indexOf(el._id) > -1) pushflag = false;
            else if((this.addeditrudata["approverids"] || []).indexOf(el._id) > -1) pushflag = false;
            else if((this.addeditrudata["managerids"] || []).indexOf(el._id) > -1) pushflag = false;
            else if((this.addeditrudata["regional_approverids"] || []).indexOf(el._id) > -1) pushflag = false;
            if(this.addusertype === "submitter"){
              if(remappids.indexOf(el._id) > -1) pushflag = true;
              else if(remmanids.indexOf(el._id) > -1) pushflag = true;
              else if(remrappids.indexOf(el._id) > -1) pushflag = true;
            }else if(this.addusertype === "approver"){
              if(remsubids.indexOf(el._id) > -1) pushflag = true;
              else if(remmanids.indexOf(el._id) > -1) pushflag = true;
              else if(remrappids.indexOf(el._id) > -1) pushflag = true;
            }else if(this.addusertype === "regional_approver"){
              if(remsubids.indexOf(el._id) > -1) pushflag = true;
              else if(remmanids.indexOf(el._id) > -1) pushflag = true;
              else if(remappids.indexOf(el._id) > -1) pushflag = true;
            }else if(this.addusertype === "manager"){
              if(remsubids.indexOf(el._id) > -1) pushflag = true;
              else if(remappids.indexOf(el._id) > -1) pushflag = true;
              else if(remrappids.indexOf(el._id) > -1) pushflag = true;
            }
            if((this.addeditrudata.__changeadd || []).indexOf(el._id) > -1) pushflag = false;
            if(pushflag) rdt.push(el);
          }
        }
      }
      return rdt;
    }
  },
}
</script>
